import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import Part from "./Part";
import {AxiosPromise} from "axios";
import {axiosInstance} from "../../api/instance";
import {store, useAppDispatch} from "../../store";
import {setSC} from "../../store/scrollReducer";
import {useSelector} from "react-redux";
import {getProfile, logoutUser} from "../../store/auth/actionCreators";
import Buttons from "../login/Buttons";
import SingleFileUploader from "../../new/SingleFileUploader";
import FileDownloader from "../../new/FileDownloader";
import {IRegisterRequest} from "../../api/auth/types";
import {Button, Modal} from "react-bootstrap";

export default function Order() {
    const [order, setOrder] = useState({parts: []});

    const [scroll, setScroll] = useState(0)

    // const [images, setImages] = useState([] as string[]);

    const [isLoading, setLoading] = useState(false);

    const [limits, setLimits] = useState(true);

    const [fileName, setFileName] = useState("" as string);

    const [alarmText, setAlarmText] = useState("" as string);

    const [show, setShow] = useState(false);

    const handleClose = () => {
        handleShow();
        setOrder({parts: []});
        remove();
    };

    const handleShow = () => setShow(false);

    const dispatch = useAppDispatch();

    const scroll1 = useSelector((store: any) => store.scroll.sc)

    const logout = () => {
        dispatch(logoutUser());
    };

    const handleScroll = () => {
        setScroll(window.scrollY);
        // store.dispatch(setSC(window.scrollY))
    };

    const handleUpButton = () => {
        window.scrollTo(0, 0);
    };


    useEffect(() => {
        console.dir(scroll1);
        if (scroll1.length < 1) {
            console.log("isU 1")
            update();
        } else {
            console.log("isU 2")
            console.log(scroll);
            setOrder(() => {
                return scroll1 as never
            })
        }
    }, []);


    useEffect(() => {
        // console.log(store.getState().scroll.sc);
        // window.scrollTo(0, store.getState().scroll.sc);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    // const timeoutRemove = setTimeout(() => {
    //     console.log('Automatic remove');
    //     setOrder({parts: []});
    //     remove();
    // }, 5000);

    const update = () => {
        const messages = (): AxiosPromise =>
            axiosInstance.get("api/part/o");
        const request = async () => {
            try {
                setLoading(true);
                await messages().then((response) => {
                    setOrder({...response.data});
                    store.dispatch(setSC(response.data));

                    //todo alarm

                    // response.data.alarm?window.alert(response.data.alarm):console.log("read_ok");
                    let login = (store.getState().auth.profileData.profile as IRegisterRequest).login;
                    if (login !== "test") {
                        if (response.data.alarm && limits) {
                            // window.alert(response.data.alarm);
                            // console.log(response.data.alarm);
                            setAlarmText(response.data.alarm);

                            setShow(true);
                            // setOrder({parts: []});
                            // remove();
                        }
                    }

                    if (response.data.name && response.data.parts.length > 0) {
                        setFileName(response.data.name.substring(0, response.data.name.length - 8));
                    }

                    setLimits(true);

                    console.dir(response.data);
                    sortMaterials(response.data);

                }, () => {
                    // this.setState((prevState) => ({count: prevState.count - 1}))
                });
            } catch (e: any) {
                console.error(e);
            }
            setLoading(false);
        };
        request();
    }

    const remove = () => {
        const messages = (): AxiosPromise =>
            axiosInstance.get("api/part/del");
        const request = async () => {
            try {
                setLoading(true);
                await messages().then((response) => {
                    setOrder({...response.data});
                    store.dispatch(setSC(response.data))
                }, () => {
                    // this.setState((prevState) => ({count: prevState.count - 1}))
                });
            } catch (e: any) {
                console.error(e);
            }
            setFileName("");
            setLoading(false);
        };
        request();
    }

    const materials = new Map<string, any[]>();

    const sortMaterials = (order: any) => {
        const parts = order.parts;
        try {
            for (let part of parts) {
                let materialName: string = part.material[0];
                if (materials.has(materialName)) {
                    let arr = materials.get(materialName)
                    arr?.push(part);
                } else {
                    let arr = [];
                    arr.push(part);
                    materials.set(materialName, arr);
                }
            }
        } catch (e: any) {
            // console.error(e);
        }
    }

    if (isLoading) {
        return (
            <div>
                <div className="spin-wrapper">
                    <div className="spinner"></div>
                    <span>Loading...</span>
                </div>
            </div>
        );
    }

    const getMaterials = (): ReactElement[] => {
        sortMaterials(order);
        // console.dir(materials);
        const forEachMaterials: ReactElement[] = [];
        materials.forEach((value, key) => {
            forEachMaterials.push(
                <div key={key}>
                    <table key={key} className="table1">
                        <thead>
                        <tr className="tr1">
                            <th style={{width: "120px"}}><p/>Матерiал:<p/></th>
                            <th style={{textAlign: "left"}}><p/>{key}<p/></th>
                        </tr>
                        </thead>
                    </table>

                    <table className="table1">
                        <thead>
                        <tr className="tr1">
                            <th style={{width: "30px"}}>№</th>
                            <th style={{width: "170px"}}>Креслення</th>
                            <th style={{width: "200px"}}>Назва деталi</th>
                            <th style={{width: "200px"}}>Матеріал та кiлькiсть</th>
                            <th style={{width: "130px"}}>Розмір (мм)</th>
                            <th>Крайка</th>
                            <th style={{width: "160px"}}>Обробки</th>
                            <th style={{width: "130px"}}>Дії</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listItems1(value)}
                        </tbody>
                    </table>
                    <p></p>
                </div>
            );
        });
        return forEachMaterials;
    };


    // const listItems = order.parts.map((data: IProps1) =>
    //     <Part key={data.id} {...data} />
    // );
    function handleChange() {
        setLimits(!limits); // инвертируем стейт
    }

    const listItems1 = (parts: any[]) => {
        return parts.map((data, index) =>
            <Part key={data.id} {...data} index={index + 1}/>
        )
    };

    return (
        <>
            <div className="col-md-12 text-center">
                <div style={{width: "1300px", display: "inline-block"}}>
                    <SingleFileUploader update={update} fileName={fileName}/>
                    <div style={{display: "inline-block"}}>
                        <input type="checkbox" id="scales" name="scales"
                               defaultChecked={limits}
                               onClick={handleChange}
                        />
                        <label htmlFor="scales">Обмеження&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div style={{display: 'inline-block'}} className="text-end">
                        <button style={{display: 'inline-block', marginRight: '10px'}} className="btn btn-secondary"
                                onClick={remove}>Очистити список
                        </button>
                        <FileDownloader/>
                        <Buttons
                            login={() => dispatch(getProfile())}
                            name={"profile"}
                            logout={logout}
                        />
                    </div>
                </div>
            </div>
            <button
                className={scroll < 300 ? "up" : "show up"}
                onClick={handleUpButton}
            >
                До гори ▲
            </button>
            <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
                <symbol
                    className="fire"
                    id="edge"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        className="active-edge"
                        d="M19.2 4.8l-6.493 6.493a1 1 0 01-1.414 0L4.8 4.8h14.4z"
                        fill="#FECB00"
                    ></path>
                    <path
                        d="M5.8 5.8h12.4v-2H5.8v2zm12.4 0v12.4h2V5.8h-2zm0 12.4H5.8v2h12.4v-2zm-12.4 0V5.8h-2v12.4h2zm0 0h-2a2 2 0 002 2v-2zm12.4 0v2a2 2 0 002-2h-2zm0-12.4h2a2 2 0 00-2-2v2zm-12.4-2a2 2 0 00-2 2h2v-2z"
                        fill="#898989"
                    ></path>
                </symbol>
            </svg>

            {/*<table className="table1">*/}
            {/*    <thead>*/}
            {/*    <tr className="tr1">*/}
            {/*        <th style={{width: "90px"}}>Матерiал:</th>*/}
            {/*        <th style={{width: "370px"}}>Назва деталi</th>*/}
            {/*        <th></th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*</table>*/}


            {/*<table className="table1">*/}
            {/*    <thead>*/}
            {/*    <tr className="tr1">*/}
            {/*        <th style={{width: "30px"}}>№</th>*/}
            {/*        <th style={{width: "170px"}}>Креслення</th>*/}
            {/*        <th style={{width: "200px"}}>Назва деталi</th>*/}
            {/*        <th style={{width: "200px"}}>Матеріал та кiлькiсть</th>*/}
            {/*        <th style={{width: "130px"}}>Розмір (мм)</th>*/}
            {/*        <th>Крайка</th>*/}
            {/*        <th style={{width: "160px"}}>Обробки</th>*/}
            {/*        <th style={{width: "130px"}}>Дії</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*{listItems}*/}
            {/*    </tbody>*/}
            {/*</table>*/}
            {getMaterials()}
            <Modal
                show={show}
                // size="xl"
                size="lg"
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>В проектi є помилки або обмеження</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={
                        {
                            whiteSpace: 'break-spaces'
                        }
                    }>
                        {alarmText}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Видалити
                    </Button>
                    <Button variant="danger" onClick={handleShow}>
                        Пропускаємо в роботу!!!
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
